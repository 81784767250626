import React from "react"
import Marquee from "react-fast-marquee"

// UI
import SectionHero from "~components/SectionHero"

// Media
import HeroZoomPhone from "~images/about/hero-zoom-phone.png"
import HeroZoomOff1 from "~images/about/hero-zoom-off1.png"
import HeroZoomOff2 from "~images/about/hero-zoom-off2.png"
import HeroZoom1 from "~images/about/hero-zoom1.jpg"
import HeroZoom2 from "~images/about/hero-zoom2.jpg"
import HeroZoom3 from "~images/about/hero-zoom3.jpg"
import HeroZoom4 from "~images/about/hero-zoom4.jpg"
import HeroZoom5 from "~images/about/hero-zoom5.jpg"
import HeroZoom6 from "~images/about/hero-zoom6.jpg"
import HeroZoom7 from "~images/about/hero-zoom7.jpg"
import HeroZoom8 from "~images/about/hero-zoom8.jpg"
import HeroGather1 from "~images/about/hero-zoom-gather1.png"
import HeroGather2 from "~images/about/hero-zoom-gather2.png"
import HeroGather3 from "~images/about/hero-zoom-gather3.png"
import HeroGather4 from "~images/about/hero-zoom-gather4.png"

const MarqueeItem = ({ imgSrc, presenter }: MarqueeItemProps): JSX.Element => (
  <div
    className={`bg-gray-500 rounded-xl overflow-hidden w-40 lg:w-64 2xl:w-80 mx-1 ${
      presenter ? "border-4 border-green-500" : ""
    }`}
  >
    <img
      src={imgSrc}
      alt="Photo of Canopy Employee"
      className={presenter ? "border-2 border-white dark:border-black rounded-lg" : ""}
    />
  </div>
)

const AboutHero: React.FC = () => (
  <>
    <SectionHero
      title={
        <>
          <span className="text-blue-500">We are Canopy.</span>
          <br />
          We believe financial products should be personal, transparent, and safe.
        </>
      }
      level={1}
      buttons={false}
    />
    <Marquee speed={20} gradientWidth={120} className="mb-1">
      <MarqueeItem imgSrc={HeroGather4} />
      <MarqueeItem imgSrc={HeroZoom1} presenter />
      <MarqueeItem imgSrc={HeroGather1} />
      <MarqueeItem imgSrc={HeroZoom8} />
      <MarqueeItem imgSrc={HeroZoomOff1} />
    </Marquee>
    <Marquee speed={15} gradientWidth={120} className="mb-1">
      <MarqueeItem imgSrc={HeroZoom5} />
      <MarqueeItem imgSrc={HeroGather2} />
      <MarqueeItem imgSrc={HeroZoom4} />
      <MarqueeItem imgSrc={HeroZoomPhone} />
      <MarqueeItem imgSrc={HeroZoom6} presenter />
    </Marquee>
    <Marquee speed={10} gradientWidth={120} className="mb-20 lg:mb-32">
      <MarqueeItem imgSrc={HeroZoom7} presenter />
      <MarqueeItem imgSrc={HeroZoom2} />
      <MarqueeItem imgSrc={HeroZoomOff2} />
      <MarqueeItem imgSrc={HeroZoom3} />
      <MarqueeItem imgSrc={HeroGather3} />
    </Marquee>
  </>
)

interface MarqueeItemProps {
  imgSrc: string
  presenter?: boolean
}

export default AboutHero
