import * as React from "react"

// UI
import SectionCallout from "~components/SectionCallout"
import Headline from "~ui/Headline"

const AboutMission = (): JSX.Element => {
  return (
    <SectionCallout color="dark" className="mb-16 md:mb-20 lg:mb-32">
      <Headline
        level={4}
        colorOverride="text-yellow-300 dark:text-yellow-800"
        className="mb-3 md:mb-6"
        data-sal="slide-up"
        data-sal-delay="200"
      >
        Our Mission
      </Headline>
      <Headline level={3} colorOverride="text-white dark:text-black" data-sal="slide-up" data-sal-delay="400">
        To enable organizations to launch personalized, transparent, and safer credit and lending products. We support
        innovative products with world-class servicing in a secure and compliant way.
      </Headline>
    </SectionCallout>
  )
}

export default AboutMission
