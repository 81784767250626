import * as React from "react"

// UI
import Section from "~components/Section"
import Headline from "~ui/Headline"

// Media
import InvestorHomebrew from "~images/about/investor-homebrew.png"
import InvestorFoundation from "~images/about/investor-foundation.png"
import InvestorCanaan from "~images/about/investor-canaan.png"
import InvestorBoxGroup from "~images/about/investor-boxgroup.png"

const AboutInvestors = (): JSX.Element => {
  return (
    <Section className="mb-16 md:mb-24 lg:mb-32">
      <Headline level={2} className="mb-8 lg:mb-12" data-sal="slide-up">
        Our Investors
      </Headline>
      <div className="grid grid-cols-2 gap-8 sm:flex sm:justify-between sm:grap-8 md:gap-16">
        <div data-sal="slide-up" data-sal-delay="150">
          <img src={InvestorFoundation} alt="Foundation Capital" className="max-h-14 filter dark:invert" />
        </div>
        <div data-sal="slide-up">
          <img src={InvestorHomebrew} alt="Homebrew" className="max-h-14 filter dark:invert" />
        </div>
        <div data-sal="slide-up" data-sal-delay="300">
          <img src={InvestorCanaan} alt="Canaan" className="max-h-14 filter dark:invert" />
        </div>
        <div data-sal="slide-up" data-sal-delay="450">
          <img src={InvestorBoxGroup} alt="Box Group" className="max-h-14 filter dark:invert" />
        </div>
      </div>
    </Section>
  )
}

export default AboutInvestors
