import * as React from "react"

// UI
import Section from "~components/Section"
import SectionAlt from "~components/SectionAlt"
import Headline from "~ui/Headline"
import Spirograph from "~components/artwork/Spirograph"

// Media
import LeadershipMattB from "~images/about/leadership-matt-bivons.jpg"
import LeadershipScottyA from "~images/about/leadership-scotty-abramson.jpg"
import LeadershipJessicaW from "~images/about/leadership-jessica-wu.jpg"
import LeadershipAnuragA from "~images/about/leadership-anurag-angara.jpg"
import LeadershipBorisB from "~images/about/leadership-boris-boroda.jpg"
import LeadershipKyleF from "~images/about/leadership-kyle-foundry.jpg"
import LeadershipKirkW from "~images/about/leadership-kirk-wells.jpg"
import LeadershipTomG from "~images/about/leadership-tom-greco.jpg"

export const leadershipList = [
  {
    name: "Matt Bivons",
    title: "Founder & CEO",
    img: LeadershipMattB,
    delay: "0",
  },
  {
    name: "Scotty Abramson",
    title: "Chief of Staff",
    img: LeadershipScottyA,
    delay: "100",
  },
  {
    name: "Thomas Greco",
    title: "SVP of Accounts",
    img: LeadershipTomG,
    delay: "200",
  },
  {
    name: "Jessica Wu",
    title: "Head of People",
    img: LeadershipJessicaW,
    delay: "300",
  },
  {
    name: "Anurag Angara",
    title: "Head of Product",
    img: LeadershipAnuragA,
    delay: "0",
  },
  {
    name: "Boris Boroda",
    title: "VP of Engineering",
    img: LeadershipBorisB,
    delay: "100",
  },
  {
    name: "Kyle Foundry",
    title: "Head of Design",
    img: LeadershipKyleF,
    delay: "200",
  },
  {
    name: "Kirk Wells",
    title: "VP of Sales",
    img: LeadershipKirkW,
    delay: "300",
  },
]

export type TUseCaseIndustriesProps = {
  leadership: { name: string; title: string; img: React.ReactElement; delay: string }[]
}

const AboutLeadership = (): JSX.Element => {
  return (
    <SectionAlt className="mb-20 md:mb-20 lg:mb-32 relative overflow-hidden">
      <Spirograph
        shape={2}
        width="w-full2x"
        position="absolute bottom-0 !top-auto"
        offsetX="-translate-x-1/4"
        offsetY="-translate-y-3/5 md:translate-y-2/3 xl:translate-y-3/5 2xl:translate-y-1/2"
        variant="yellow"
        zIndex="z-0"
      />
      <Section>
        <Headline level={2} className="mb-8 md:mb-12 relative z-1">
          Leadership Team
        </Headline>
        <div className="grid grid-cols-2 md:grid-cols-4 gap-6 lg:gap-8 mb-4">
          {leadershipList.map((i) => (
            <div className="relative z-10" key={i.name} data-sal="slide-up" data-sal-delay={i.delay}>
              <div className="bg-yellow-50 mb-3 md:mb-4 rounded-3xl overflow-hidden">
                <img src={i.img} alt={i.name} className="filter grayscale mix-blend-multiply" />
              </div>
              <Headline level={5}>{i.name}</Headline>
              <p className="text-xs md:text-sm text-orange-800 dark:text-orange-700 md:mt-1">{i.title}</p>
            </div>
          ))}
        </div>
      </Section>
    </SectionAlt>
  )
}

export default AboutLeadership
