import React from "react"

// UI
import Layout from "../components/layout"
import SEO from "../components/seo"
import AboutHero from "~content/about/Hero"
import AboutMission from "~content/about/Mission"
import AboutStory from "~content/about/Story"
import AboutLeadership from "~content/about/Leadership"
import AboutInvestors from "~content/about/Investors"
import AboutAwards from "~content/about/Awards"
import AboutCareersCTA from "~content/about/CareersCTA"

const AboutPage: React.FC = () => {
  return (
    <Layout>
      <SEO
        canonicalTag="https://canopyservicing.com/about/"
        description="Canopy Servicing is an API-first platform that is enabling the next generation of credit and lending products."
        title="About | Canopy Servicing"
      />
      <AboutHero />
      <AboutMission />
      <AboutStory />
      <AboutLeadership />
      <AboutInvestors />
      <AboutAwards />
      <AboutCareersCTA />
    </Layout>
  )
}

export default AboutPage
