import * as React from "react"

// UI
import SectionTwoCols, { TwoColCard } from "~components/SectionTwoCols"
import Headline from "~ui/Headline"

// Media
import awardImg from "~images/about/award.png"

const AboutAwards = (): JSX.Element => {
  return (
    <SectionTwoCols className="mb-20 md:mb-24 lg:mb-40 sm:mt-8 md:mt-16 lg:mt-24">
      <TwoColCard data-sal="slide-up">
        <Headline level={2} className="mb-6 md:mb-8">
          Our Recent Awards
        </Headline>
        <p className="md:text-lg">
          We&#39;re honored to be recognized as an innovative company with a top-tier product. These awards are a
          testament to the incredible team we have at Canopy.
        </p>
      </TwoColCard>
      <TwoColCard
        className="bg-green-900 py-8 lg:py-12 px-8 lg:px-12"
        data-sal="slide-up"
        data-sal-delay="150"
        noPadding
      >
        <img src={awardImg} alt="Canopy Servicing Award" data-sal="slide-up" data-sal-delay="300" />
      </TwoColCard>
    </SectionTwoCols>
  )
}

export default AboutAwards
