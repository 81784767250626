import * as React from "react"

// UI
import Section from "~components/Section"
import Headline from "~ui/Headline"

const AboutStory = (): JSX.Element => {
  return (
    <Section className="mb-16 md:mb-24 lg:mb-32">
      <Headline level={4} colorOverride="text-blue-500" className="mb-3 md:mb-6" data-sal="slide-up">
        Our Founding Story
      </Headline>
      <p className="md:text-lg" data-sal="slide-up" data-sal-delay="200">
        Years ago, Canopy co-founder and CEO, Matt Bivons, decided to close a credit card. He called customer service to
        get his balance total, made his payment, and assumed he was in the clear. That is, until two months later when
        he saw his credit score drop by 20 points. Frustrated to see it was due to unpaid fees on the credit card he
        thought he&#39;d already paid, he called back.
        <br />
        <br />
        As it turned out, Matt had interest that carried over from mid-cycle, and that interest had accumulated late
        fees. To add insult to injury, the representative could not tell him how much more he owed until the end of the
        cycle that following month or do anything about the credit score drop.
        <br />
        <br />
        Matt quickly realized he was far from alone in this scenario. People experience these frustrations every day as
        fintechs, banks, and credit card companies simply don&#39;t have access to real-time data. This led Matt and the
        founding team to start Canopy, a flexible, personalized platform for lending innovators.
      </p>
    </Section>
  )
}

export default AboutStory
